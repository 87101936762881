<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('company.company_profile') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <!--          <router-link to="/dashboard" class="btn btn-light font-weight-bolder"><v-icon>mdi-chevron-left</v-icon> Back</router-link>-->
          <!--end::Button-->
        </div>
      </div>

      <div class="card-body">
        <b-card class="mb-1" no-body>
          <b-card-header class="p-1" header-tag="header" role="tab">
            <b-button v-b-toggle.accordion-1 block class="text-left font-weight-bold text-dark" variant="link">
              {{ $t('company.basic_information') }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1"
                      :visible="((validation.business_name && validation.business_name.length > 0) || (validation.currency_id && validation.currency_id.length > 0))"
                      accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-group row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.upload_logo') }}</label>
                  <div class="form-group">
                    <custom-upload-image-2 :image-url="logo_url" :start-link="'base'" :upload="dir_upload"
                                           @file="listenerAttachment"></custom-upload-image-2>
                  </div>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.business_name') }}<span class="text-danger">*</span></label>
                  <input v-model="data.business_name"
                         :class="validation && validation.business_name ? 'is-invalid' : ''"
                         :placeholder="$t('company.business_name')"
                         class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.business_name"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.business_name[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.business_name_en') }}<span class="text-danger">*</span></label>
                  <input v-model="data.business_name_en"
                         :class="validation && validation.business_name_en ? 'is-invalid' : ''"
                         :placeholder="$t('company.business_name_en')"
                         class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.business_name_en"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.business_name_en[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.business_type') }}</label>
                  <select id="activity_type" v-model="data.activity_type_id" class="custom-select" name="">
                    <option v-for="row in activity_type_list" :key="row.id" :value="row.id">
                      {{ row.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation.activity_type_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.activity_type_id[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.first_name') }}</label>
                  <input v-model="data.first_name" :class=" validation && validation.first_name ? 'is-invalid' : ''"
                         :placeholder="$t('users.first_name')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.first_name"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.first_name[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.last_name') }}</label>
                  <input v-model="data.last_name" :class=" validation && validation.last_name ? 'is-invalid' : ''"
                         :placeholder="$t('users.last_name')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.last_name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.last_name[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.currency') }}<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <select id="currency" v-model="data.currency_id"
                            :class="validation && validation.currency_id ? 'is-invalid' : ''" class="custom-select">
                      <option v-for="row in currency_list" :key="row.id" :value="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus"
                                                                                                style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.currency_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.language') }}</label>
                  <select id="language" v-model="data.language_id" class="custom-select" name="">
                    <option v-for="row in language_list" :key="row.id" :value="row.id">{{ row.name }}</option>
                  </select>
                  <span v-if="validation && validation.language_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.language_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.time_zone') }}</label>
                  <select id="timezone_id" v-model="data.timezone_id" class="custom-select" name="">
                    <option v-for="row in time_zone_list" :key="row.id" :value="row.id">{{ row.name }}</option>
                  </select>
                  <span v-if="validation && validation.timezone_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.timezone_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.date_format') }}</label>
                  <select id="date_format" v-model="data.date_format" class="custom-select" name="">
                    <option v-for="(row, index) in date_format_list" :key="'ff'+index" :value="row">{{ row }}</option>
                  </select>
                  <span v-if="validation && validation.date_format"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.date_format[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.printing_method') }}</label>
                  <select id="printing_method" v-model="data.printing_method" class="custom-select" name="">
                    <option v-for="row in printing_method_list" :key="row.id" :value="row.id">{{ row.name }}</option>
                  </select>
                  <span v-if="validation && validation.printing_method"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.printing_method[0] }}
                  </span>
                </div>
                <div class="col-lg-6  mb-5">
                  <label>{{ $t('activity_logs') }}</label>
                  <multiselect
                      v-model="data.log_keys"
                      :multiple="true"
                      :options="activity_logs"
                      :placeholder="$t('activity_logs')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      label="key"
                      track-by="id">
                  </multiselect>
                </div>
                <div class="col-lg-6  mb-5">
                  <label>{{ $t('inventory_statistics.inventory_statistics') }}</label>
                  <multiselect
                      v-model="data.inventory_statistics"
                      :multiple="true"
                      :options="inventory_statistics_option"
                      :placeholder="$t('inventory_statistics.inventory_statistics')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      label="title"
                      track-by="id">
                  </multiselect>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('num_movements') }}</label>
                  <input v-model="data.num_movements"
                         :class=" validation && validation.num_movements ? 'is-invalid' : ''"
                         :placeholder="$t('num_movements')"
                         class="form-control"
                         min="1" type="number"/>
                  <span v-if="validation && validation.num_movements"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.num_movements[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('tax_no') }}</label>
                  <input v-model="data.tax_no" :class=" validation && validation.tax_no ? 'is-invalid' : ''"
                         :placeholder="$t('tax_no')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.tax_no" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.tax_no[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('business_record') }}</label>
                  <input v-model="data.business_record"
                         :class=" validation && validation.business_record ? 'is-invalid' : ''"
                         :placeholder="$t('business_record')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.business_record"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.business_record[0] }}
                  </span>
                </div>


                <div class="col-lg-6 mb-5">
                  <label>{{ $t('business_record_attachment') }}</label>
                  <upload-file
                      v-if="reloadUploadAttachmentCompany"
                      :inner-id="'companies'"
                      :placeholder="$t('business_record_attachment')"
                      :start-link="'base'"
                      :upload="dir_upload"
                      v-bind:valueprop="{name:'company',path:business_record_attachment_url}"
                      @file="listenerAttachmentCompany">
                  </upload-file>
                  <span v-if="validation && validation.business_record_attachment"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.business_record_attachment[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('identity_name_for_invoice') }}</label>
                  <input v-model="data.identity_name_for_invoice"
                         :class=" validation && validation.identity_name_for_invoice ? 'is-invalid' : ''"
                         :placeholder="$t('identity_name_for_invoice')"
                         class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.identity_name_for_invoice"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.identity_name_for_invoice[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.debit_note') }}</label>
                  <select id="language" v-model="data.debit_document" class="custom-select" name="">
                    <option v-for="row in debit_document_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.debit_document"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.debit_document[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-8 pt-3">
                  <b-form-checkbox v-model="data.is_show_main_name_company_in_print"
                                   :class="validation && validation.is_show_main_name_company_in_print ? 'is-invalid' : ''"
                                   name="check-button"
                                   size="lg" switch>
                    {{ $t('is_show_main_name_company_in_print') }}
                  </b-form-checkbox>
                  <span v-if="validation && validation.is_show_main_name_company_in_print"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.is_show_main_name_company_in_print[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-8 pt-3">
                  <b-form-checkbox v-model="data.is_setting_message"
                                   :class="validation && validation.is_setting_message ? 'is-invalid' : ''"
                                   name="check-button"
                                   size="lg" switch>
                    {{ $t('is_show_setting_message') }}
                  </b-form-checkbox>
                  <span v-if="validation && validation.is_setting_message"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.is_setting_message[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-8 pt-3" v-if="is_activity_laundry">
                  <b-form-checkbox v-model="data.category_laundry"
                                   :class="validation && validation.category_laundry ? 'is-invalid' : ''"
                                   name="check-button"
                                   size="lg" switch>
                    {{ $t('category_laundry') }}
                  </b-form-checkbox>
                  <span v-if="validation && validation.category_laundry"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.category_laundry[0] }}
                  </span>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card class="mb-1" no-body>
          <b-card-header class="p-1" header-tag="header" role="tab">
            <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark" variant="link">
              {{ $t('company.address_information') }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2"
                      :visible="((validation.country_id && validation.country_id.length > 0) || (validation.city_id && validation.city_id.length > 0))"
                      accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-group row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.country') }}<span class="text-danger">*</span></label>
                  <!--                    <select name="" id="countries" v-model="data.country_id" class="custom-select" :class="validation && validation.country_id ? 'is-invalid' : ''">-->
                  <!--                      <option v-for="row in countries" :value="row.code2" :key="row.code2">-->
                  <!--                        {{ row.name }}-->
                  <!--                      </option>-->
                  <!--                    </select>-->

                  <multiselect v-model="country"
                               :class="validation && validation.country_id ? 'is-invalid' : ''"
                               :internal-search="false"
                               :multiple="false"
                               :options="countries"
                               :placeholder="$t('company.country')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               label="name"
                               track-by="code2"
                               @search-change="getCountries($event)">
                  </multiselect>
                  <span class="form-text text-muted" style="text-align:end">{{
                      $t('type_at_least_three_letters') + ' ' + $t('company.country')
                    }}.</span>

                  <span v-if="validation && validation.country_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.country_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.city') }}<span class="text-danger">*</span></label>

                  <div class="input-group mb-3">
                    <multiselect v-model="city"
                                 :class="validation && validation.city_id ? 'is-invalid' : ''"
                                 :multiple="false"
                                 :options="cities" :placeholder="$t('company.city')"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :taggable="false"
                                 label="name"
                                 track-by="id">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a :href="data.country_id?'/settings/cities/'+data.country_id:'#'" class="btn btn-primary"
                         target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.city_id[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5" v-if="data.country.id != 192">
                  <label>{{ $t('company.postal_code') }}</label>
                  <input v-model="data.postal_code" :class="validation && validation.postal_code ? 'is-invalid' : ''"
                         :placeholder="$t('company.postal_code')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.postal_code"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.postal_code[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5" v-if="data.country.id != 192">
                  <label>{{ $t('company.district') }}</label>
                  <input v-model="data.district" :class="validation && validation.district ? 'is-invalid' : ''"
                         :placeholder="$t('company.district')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.district" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.district[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{data.country.id != 192 ? $t('company.address_1') : $t('national_address') }}</label>
                  <input v-model="data.address_1" :class="validation && validation.address_1 ? 'is-invalid' : ''"
                         :placeholder="$t('company.address_1')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.address_1[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5" v-if="data.country.id != 192">
                  <label>{{ $t('company.address_2') }}</label>
                  <input v-model="data.address_2" :class="validation && validation.address_2 ? 'is-invalid' : ''"
                         :placeholder="$t('company.address_2')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.address_2[0] }}
                  </span>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card class="mb-1" no-body>
          <b-card-header class="p-1" header-tag="header" role="tab">
            <b-button v-b-toggle.accordion-3 block class="text-left font-weight-bold text-dark" variant="link">
              {{ $t('company.contact_information') }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" :visible="(validation.mobile && validation.mobile.length > 0)"
                      accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-group row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.email') }}<span class="text-danger">*</span></label>
                  <input v-model="data.email" :class="validation && validation.email ? 'is-invalid' : ''"
                         :placeholder="$t('company.email')"
                         class="form-control" type="email"/>
                  <span v-if="validation && validation.email"
                        class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.mobile') }}</label>
                  <vue-tel-input v-model="data.mobile"
                                 :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                  <span v-if="validation && validation.mobile"
                        class="fv-plugins-message-container invalid-feedback">{{ validation.mobile[0] }}</span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label for="phone_no">{{ $t('company.phone') }}</label>
                  <vue-tel-input id="phone_no" v-model="data.telephone"
                                 :class="validation && validation.telephone ? 'is-invalid' : ''"></vue-tel-input>
                  <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">{{
                      validation.telephone[0]
                    }}</span>
                </div>


              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card class="mb-1" no-body>
          <b-card-header class="p-1" header-tag="header" role="tab">
            <b-button v-b-toggle.accordion-4 block class="text-left font-weight-bold text-dark" variant="link">
              {{ $t('company.banking_data') }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-group row">

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.account_holder_name') }}</label>
                  <input v-model="data.account_holder_name"
                         :class="validation && validation.account_holder_name ? 'is-invalid' : ''"
                         :placeholder="$t('company.account_holder_name')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.account_holder_name"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.account_holder_name[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.account_holder_number') }}</label>
                  <input v-model="data.account_holder_number"
                         :class="validation && validation.account_holder_number ? 'is-invalid' : ''"
                         :placeholder="$t('company.account_holder_number')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.account_holder_number"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.account_holder_number[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.iban') }}</label>
                  <input v-model="data.iban" :class="validation && validation.iban ? 'is-invalid' : ''"
                         :placeholder="$t('company.iban')"
                         class="form-control" type="text"/>
                  <span v-if="validation && validation.iban" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.iban[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.bank_name') }}</label>
                  <input v-model="data.bank_name" :class="validation && validation.bank_name ? 'is-invalid' : ''"
                         :placeholder="$t('company.bank_name')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.bank_name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.bank_name[0] }}
                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('company.bank_branch') }}</label>
                  <input v-model="data.bank_branch" :class="validation && validation.bank_branch ? 'is-invalid' : ''"
                         :placeholder="$t('company.bank_branch')" class="form-control"
                         type="text"/>
                  <span v-if="validation && validation.bank_branch"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.bank_branch[0] }}
                  </span>
                </div>


              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-6">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-user",
  data() {
    return {
      mainRoute: 'settings/company',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'companies',
      date_format_list: [
        'dd.mm.yyyy',
        'mm/dd/yyyy',
        'mm-dd-yyyy',
        'yyyy/mm/dd',
        'yyyy-mm-dd',
        'dd Month yyyy',
        'Month dd yyyy',
        'Mon dd yyyy'
      ],
      // date_format_list: [{id: 1, name: this.$t('day')}, {id: 2, name: this.$t('month')}, {id: 3, name: this.$t('year')}],
      printing_method_list: [{id: 0, name: this.$t('browse')}, {id: 1, name: this.$t('pdf')}],
      //  commited_method_list: [{id: 0, name: this.$t('none')}, {id: 1, name: this.$t('all')}, {id: 2, name: this.$t('many')}],

      activity_type_list: [{id: 0, name: this.$t('services_products')}, {id: 1, name: this.$t('products_only')}, {
        id: 2,
        name: this.$t('services_only')
      }],
      currency_list: [],
      language_list: [],
      time_zone_list: [],
      debit_document_list: [],
      countries: [],
      cities: [],
      activity_logs: [],
      inventory_statistics_option:[],
      is_activity_laundry: false,
      data: {
        business_name: null,
        business_name_en: null,
        activity_type_id: null,
        first_name: null,
        last_name: null,
        currency_id: null,
        language_id: null,
        debit_document: null,
        timezone_id: null,
        date_format: null,
        printing_method: null,
        country_id: null,
        city_id: null,
        postal_code: null,
        district: null,
        address_1: null,
        address_2: null,
        mobile: null,
        telephone: null,
        email: null,
        logo: null,
        log_keys: [],
        num_movements: null,
        business_record_attachment: null,
        tax_no: null,
        business_record: null,
        is_show_main_name_company_in_print: true,
        is_setting_message: true,
        category_laundry: true,
        identity_name_for_invoice: null,
        inventory_statistics: [],
        account_holder_name: null,
        account_holder_number: null,
        iban: null,
        bank_name: null,
        bank_branch: null,
      },
      logo_url: null,
      reloadUploadAttachment: true,
      isEditing: false,
      validation: {},
      country: null,
      city: null,
      business_record_attachment_url: null,
      reloadUploadAttachmentCompany: true,
    };
  },
  watch: {
    country: function (val) {
      if (val) {
        this.data.country_id = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_id = null;
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
    "data.business_record": function (val) {
      if (val) {
        this.data.business_record = val.replace(/[^\w\s]/gi, "").replace(/[^0-9]/g, "");
      }
    },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      this.update();
      // } else {
      //   this.create();
      // }
    },

    // create() {
    //     ApiService.post(this.mainRoute, {
    //         ...this.data,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.validation = error.response ? error.response.data.errors : null;
    //         console.log(this.validation);
    //     });
    // },

    update() {
      this.validation = {};
      ApiService.post(this.mainRoute + '/setCompany', {
        ...this.data,
      }).then((response) => {
        this.validation = {};
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      this.reloadUploadAttachmentCompany = false;
      ApiService.get(this.mainRoute + '/getCompany').then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.is_activity_laundry = response.data.data.category_laundry;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.logo_url = response.data.data.logo_url;
        this.business_record_attachment_url = response.data.data.business_record_attachment_url;
        this.reloadUploadAttachment = true;
        this.reloadUploadAttachmentCompany = true;
      });
    },

    getActivityLogList() {
      ApiService.get(`${this.mainRouteDependency}/activity_log_list`).then((response) => {
        this.activity_logs = response.data.data;
      });
    },
    getConstent(){
      ApiService.get(`${this.mainRouteDependency}/company_data_constants`).then((response) => {
        let data = response.data.data;
        console.log(data)
        this.inventory_statistics_option = data.inventory_statistics;
        this.currency_list = data.currencies;
        this.language_list = data.languages;
        this.time_zone_list = data.timezones;
      });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data.filter(row => row.name != null);
        });
    },

    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getDebitDocument() {
      ApiService.get(this.mainRouteDependency + "/debit_document").then((response) => {
        this.debit_document_list = response.data.data;
      });
    },
    listenerAttachment(event) {
      if (event) {
        this.logo_url = event.pathDB;
        this.data.logo = event.name;
      }
    },
    listenerAttachmentCompany(event) {
      if (event) {
        this.business_record_attachment_url = event.pathDB;
        this.data.business_record_attachment = event.name;
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.company_profile")}]);
    this.getCountries();
    this.getData();
    this.getActivityLogList();
    this.getDebitDocument();
    this.$root.$emit('bv::toggle::collapse', 'accordion-1')
  },
  created() {
    this.getConstent();
  }
};
</script>


